import React from 'react'
import Layout from '../layouts/de'
import SubTitle from '../components/Titles/SubTitle'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SubTitle>Seite nicht gefunden.</SubTitle>
  </Layout>
)

export default NotFoundPage
